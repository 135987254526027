<template>
  <b-popover
    :id="'popoverFiltroCustom'"
    ref="FiltroCustom"
    target="filtroColor"
    :triggers="['click']"
    placement="auto"
    :show.sync="popoverShow"
    @show="onShow"
  >
    <template #title>
      <div class="cadastro-filtro-header">
        <span>Selecione uma Cor</span>

        <b-button
          variant="none"
          class="close"
          @click="onClose"
        >
          <span aria-hidden="true">&times;</span>
        </b-button>
      </div>
    </template>

    <div
      @keyup.esc.stop="onClose"
    >

      <div>
        <!-- v-if="!value" -->
        <div
          class="nome-filtro"
        >
          <label>Nome do filtro</label>
          <b-input
            v-model="nomeFiltro"
            autofocus
          />
        </div>

        <div class="cor-filtro">
          <label>Cor do filtro</label>
          <div
            v-for="cor in cores"
            :key="cor"
            :style="`background-color:${cor}`"
            :class="{'filtro-selecionada': value ? corFiltro == cor : false}"
            @click.prevent.stop="corFiltro = cor"
          />

        </div>
      </div>
      <div
        v-if="value.nome && value.nome != ''"
        class="btn-filtro"
      >
        <b-btn
          variant="primary"
          class="btn-sm w-100 mr-50 mt-50"
          :disabled="desabilitarSalvar"
          @click.stop="salvar(true)"
        >
          Salvar
        </b-btn>
      </div>

      <div
        v-else
        style="btn-filtro"
        @click.stop="salvar(false)"
      >
        <b-btn
          variant="outline-primary"
          class="btn-sm w-100"
          :disabled="desabilitarSalvar"
        >
          Criar
        </b-btn>
      </div>
    </div>
  </b-popover>
</template>

<style lang="scss">

.cadastro-filtro-header {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .close {
    right: 0;
  }

  .back {
    color: inherit;
    text-shadow: none;
    background-color: transparent;
    -webkit-appearance: none;
    font-size: 1.341rem;
    font-weight: 300;
    line-height: 1;
    opacity: 0.5;
    padding: 0;
    border: 0;
  }
}

.filtro {
  max-width: 210px;
  border-radius: 4px;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 4px;
  }

  & span {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    margin-right: 0.3rem;
  }

  & span:first-child {
    width: 210px;
    margin: 0.2rem;
    margin-left: 0.3rem;
  }
}

.filtro-selecionada-edit {
  float: right !important;
}

.nome-filtro {
  display: block;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;

  & > label {
    font-size: 0.9rem;
    display: block;
  }
}

.cor-filtro {
  display: block;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;

  & > label {
    font-size: 0.9rem;
    display: block;
  }

  & > div {
    height: 40px;
    width: 45px;
    margin: 0.2rem;
    border-radius: 4px;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .filtro-selecionada {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.651), 0 6px 20px 0 rgba(0, 0, 0, 0.658);
  }
}

.btn-filtro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;

  & > button {
    height: 35px !important;
    min-height: 35px !important;
  }
}

.search-item:focus {
  border-color: var(--primary)
}

.procurar-filtro {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
}

.desabilitar-inputs {
  pointer-events: none;
}
</style>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import api from '../../../../../services/api' // colocar verificador como prop

export default {
  name: 'CadastroFiltroCustom',
  components: {
    // FontAwesomeIcon,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      faChevronLeft,
      popoverShow: false,
      mode: 'none',
      valorAProcurar: null,
      filtroNovo: {
        id: 0,
        nome: '',
        cor: '',
        usuarios: [],
        empresas: { vinculadas: [], naoVinculadas: [] },
        filtrosDocs: [],
      },
      cores: ['#187FD9', '#D62839', '#5FAD56', '#E6DE00', '#EF709D', '#353F3C', '#B8972A', '#707070'], // , '#ED7D3E' , '#193051'
      nomeFiltro: '',
      corFiltro: '',
      desabilitarSalvar: false,
    }
  },
  computed: {
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.corFiltro = value.cor
        this.nomeFiltro = value.nome
        this.filtroNovo = { id: 0 }
      }
    },
  },
  created() {
    this.nomeFiltro = this.value ? this.value.nome : ''
    this.corFiltro = this.value ? this.value.cor : ''
    this.popoverShow = false
  },
  methods: {
    carregar() {
    },
    onClose() {
      this.popoverShow = false
    },
    onShow() {
      this.carregar()
      this.popoverShow = true
    },
    mudarCor(cor) {
      if (this.value) {
        this.value.cor = cor
      } else {
        this.filtroNovo.cor = cor
      }
    },
    salvar(atualizar) {
      if (this.nomeFiltro && this.nomeFiltro !== '') {
        this.desabilitarSalvar = true
        api.verificarNome(this.nomeFiltro)
          .then(payload => {
            this.desabilitarSalvar = false
            if (payload.data === false) {
              if (atualizar) {
                this.value.nome = this.nomeFiltro
                this.value.cor = this.corFiltro
              } else {
                this.filtroNovo.id = 0
                this.filtroNovo.nome = this.nomeFiltro
                this.filtroNovo.cor = this.corFiltro
                this.$emit('criar', this.filtroNovo)
              }
              this.popoverShow = false
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Nome Existente',
                  icon: 'fa-solid fa-circle-exclamation',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(() => {
            this.desabilitarSalvar = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro ao salvar',
                icon: 'fa-solid fa-circle-exclamation',
                variant: 'danger',
              },
            })
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Informe um Nome',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      }
    },
    // atualizar() {
    //   if (this.mudarNome(true)) {
    //     this.value.cor = this.corFiltro
    //   }
    //   console.log(this.value)
    // },
    // criar() { // colocar campo obrigatório
    //   if (this.mudarNome(false)) {
    //     this.filtroNovo.cor = this.corFiltro
    //     this.$emit('criar', this.filtroNovo)
    //   }
    // },
  },
}
</script>
